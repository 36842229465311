import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://anywhere-api.com'
});
// const instance = axios.create({
//   baseURL: 'http://localhost:8080'
// });

export default instance;
