import { Accordion, createStyles, Text, Group, Anchor, Title } from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';
import Break from '../Break';
import { useEffect, useState } from 'react';

import { useMediaQuery } from '@mantine/hooks';
const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
    borderRadius: theme.radius.sm
  },
  item: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2]
  }
}));

function SourcesAccordion(props: any) {
  const { sources, setSearchText, setPageNumber, promptType, setActiveTab } = props;
  const { classes } = useStyles();
  const [value, setValue] = useState<string | null>(null);
  const isMobile = useMediaQuery('(max-width: 600px)');
  function handleSetHighlighted(value: any) {
    setSearchText(value.text);
    setPageNumber(value.pageNumber);
    if (isMobile) {
      setActiveTab('Preview');
    }
  }
  // Check the promptType and set the value to either null or '0' depending if prompType == search or chat in a useEffect

  useEffect(() => {
    if (promptType === 'Search') {
      setValue(null);
    } else {
      setValue(null);
    }
  }, [promptType]);

  function handleSetValue() {
    if (value === null) {
      setValue('0');
    } else {
      setValue(null);
    }
  }
  return (
    <>
      <Group position="right">
        {value === null ? ( // if value is null, show the button to show sources
          <Anchor
            styles={{ cursor: 'pointer' }}
            onClick={() => handleSetValue()}
            style={{ cursor: 'pointer' }}
            key={uuidv4()}
            size="xs">
            Show Sources
          </Anchor> // if value is not null, show the button to hide sources
        ) : (
          <Anchor
            styles={{ cursor: 'pointer' }}
            onClick={() => handleSetValue()}
            style={{ cursor: 'pointer' }}
            key={uuidv4()}
            size="xs">
            Hide Sources
          </Anchor>
        )}
      </Group>
      <Accordion
        sx={{ maxWidth: 500 }}
        mx="auto"
        variant="filled"
        value={value}
        onChange={setValue}
        chevron={<></>}
        classNames={classes}
        className={classes.root}>
        <Accordion.Item className={classes.item} value={'0'}>
          <Accordion.Panel>
            {sources &&
              sources.map(
                (
                  responseText: { pageNumber: any; similarity: number; text: any },
                  indexRes: number
                ) => (
                  <>
                    <Break key={uuidv4()} height={0.5} />
                    <Group position="apart">
                      <Title color="dimmed" key={uuidv4()} order={6}>
                        Source {indexRes + 1}
                      </Title>
                      <Anchor
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleSetHighlighted(responseText)}
                        key={uuidv4()}
                        size="xs">
                        Page: {responseText && responseText.pageNumber} Score:{' '}
                        {responseText.similarity && responseText.similarity.toFixed(2)}
                      </Anchor>
                    </Group>
                    <Break key={uuidv4()} height={0.05} />
                    <Text key={uuidv4()} size="sm">
                      {responseText.text}
                    </Text>
                    <Break key={uuidv4()} height={0.05} />
                  </>
                )
              )}{' '}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default SourcesAccordion;
