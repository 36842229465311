import { ActionIcon, Group, ScrollArea, TextInput } from '@mantine/core';
import { useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';

interface SinglePageProps {
  pdf: any;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
}

function SinglePage({
  pdf,
  pageNumber,
  setPageNumber,
  searchText,
  setSearchText
}: SinglePageProps) {
  const [numPages, setNumPages] = useState(0);
  const [scale, setScale] = useState(0.75);
  const [heightPage, setHeightPage] = useState(550);
  const isMobile = useMediaQuery('(max-width: 600px)');
  useEffect(() => {
    if (isMobile) {
      setScale(0.75);
      setHeightPage(500);
    } else {
      setScale(1);
      setHeightPage(550);
    }
  }, [isMobile]);

  function highlightPattern(text: string, pattern: string) {
    if (text.includes(pattern)) {
      return text.replace(pattern, (value: any) => `<mark>${value}</mark>`);
    }

    let longestSubPattern = '';
    let longestSubPatternLength = 0;

    // CODE TO ITERATE OVER THE PATTERN AND FIND THE LONGEST SUB-PATTERN
    for (let i = 0; i < pattern.length; i++) {
      for (let j = i + 1; j < pattern.length + 1; j++) {
        const subPattern = pattern.slice(i, j);
        if (text.includes(subPattern) && subPattern.length > longestSubPatternLength) {
          longestSubPattern = subPattern;
          longestSubPatternLength = subPattern.length;
        }
      }
    }

    const wordsInLongestSubPattern = longestSubPattern.split(/\s+/).length;

    if (wordsInLongestSubPattern > 3) {
      const highlightedText = text.replace(
        longestSubPattern,
        (value: any) => `<mark>${value}</mark>`
      );
      return highlightedText;
    } else {
      return text;
    }
  }

  const textRenderer = useCallback(
    (textItem: { str: any }) => highlightPattern(textItem.str, searchText),
    [searchText]
  );

  function onChange(event: { target: { value: string } }) {
    setSearchText(event.target.value);
  }

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  function changePage(offset: number) {
    setPageNumber(pageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function zoomIn() {
    setScale(scale + 0.1);
  }

  function zoomOut() {
    setScale(scale - 0.1);
  }

  return (
    <>
      <div>
        <ScrollArea type="auto" style={{ width: '100%', height: '95%' }}>
          <Group spacing="xs" position="center">
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: heightPage
              }}>
              <Document
                file={pdf}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} customTextRenderer={textRenderer} scale={scale} />
              </Document>
            </div>
          </Group>
        </ScrollArea>
        <Group spacing="xs" position="center">
          <ActionIcon variant="outline" disabled={pageNumber <= 1} onClick={previousPage}>
            <IconChevronLeft size={16} />
          </ActionIcon>

          <p>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>
          <ActionIcon variant="outline" disabled={pageNumber >= numPages} onClick={nextPage}>
            <IconChevronRight size={16} />
          </ActionIcon>
          <ActionIcon variant="outline" onClick={zoomOut}>
            -
          </ActionIcon>
          <ActionIcon variant="outline" onClick={zoomIn}>
            +
          </ActionIcon>

          <TextInput value={searchText} onChange={onChange} />
        </Group>
      </div>
    </>
  );
}

export default SinglePage;
