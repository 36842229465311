// Get a user from session storage;
export const getUser = () => {
  const user = sessionStorage.getItem('user');
  if (!user) return null;
  if (user) return JSON.parse(user);
  return null;
};

// Get the token from the user object
export const getToken = () => {
  const token = sessionStorage.getItem('token');
  if (!token) return null;
  return JSON.parse(token);
};

// Set the user in session storage
export const setUserSession = (token: any, user: any) => {
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('token', JSON.stringify(token));
};

export const setUser = (user: any) => {
  sessionStorage.setItem('user', JSON.stringify(user));
};

// Remove the user from session storage
export const resetUserSession = () => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('token');
};
