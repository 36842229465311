import { useMantineTheme } from '@mantine/core';

interface BreakProps {
  height?: number;
  width?: number;
  isLine?: boolean;
}
/**
 * Break is a functional component that creates a line break in the layout of your application
 *
 * @param {BreakProps} props - The props of the component.
 * @param {number} [props.height=0.5] - The height of the line break.
 * @param {number} [props.width=0.5] - The width of the line break.
 * @param {boolean} [props.isLine=false] - A flag that indicates whether to render a horizontal line or not.
 *
 * @returns {JSX.Element} - Renders the Break component.
 *
 * @example
 * <Break height={1} width={1} isLine={true} />
 * <Break height={0.5} width={1} isLine={false} />
 */

function Break({ height, width, isLine }: BreakProps): JSX.Element {
  const theme = useMantineTheme();
  if (isLine) {
    return (
      <>
        <div
          style={{
            lineHeight: height,
            width
          }}>
          <br />
        </div>
        <hr color={theme.colors.blue[0]} />
        <div
          style={{
            lineHeight: height,
            width
          }}>
          <br />
        </div>
      </>
    );
  }
  return (
    <>
      <div
        style={{
          lineHeight: height,
          width
        }}>
        <br />
      </div>
    </>
  );
}

Break.defaultProps = {
  height: 0.5,
  width: 0.5,
  isLine: false
};

export default Break;
